import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";
import { isBoolean } from "lodash";

import Order, {
	OrderTransferType,
} from "../../../../../../../../../../services/Order";
import OrderDistributionSettings from "../../../../../../../../../../services/OrderDistributionSettings";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import {
	Button,
	StyledRow,
} from "../../../../../../../../../../components/common";

// import {
// 	generateAccessName,
// 	AccessKey,
// 	hasAccess,
// } from "../../../../../../../../../../access";

const OrderTransferButtonsBase: React.FC<OrderTransferButtonsBase.Props> = ({
	disabled,
	orderId,
	order,
}) => {
	// const ACCESS_SECTION = AccessKey.ORDERS;
	// const ACCESS_NAME = AccessKey.CALL_ORDER_CLIENT;

	const options = useMemo(() => {
		const payload: OrderDistributionSettings.SubscribeOptions = {
			limit: 100,
			includeTaxiServices: true,
		};
		return payload;
	}, []);

	const { models } = useModelSubscribe(options, OrderDistributionSettings);

	const transfer = useMemo(() => order?.additionalFields?.transfer, [order]);

	const isNotEnabled = useMemo(() => {
		console.log("[Order] OrderTransferButtonsBase", order);

		const taxiServiceId = order?.taxiService?.id;

		if (!taxiServiceId) return true;

		let value: OrderDistributionSettings.Model | undefined;

		if (!value) {
			value = models.find(
				(item) =>
					!item.root &&
					item?.taxiServiceIds?.includes(taxiServiceId) &&
					item.active,
			);
		}

		if (!value) {
			value = models.find((item) => item.root);
		}

		if (!value) return true;

		return !value?.settings?.externalOrderDistribution?.send?.enabled;
	}, [models, order]);

	const currentTransferStage = useMemo(() => {
		const value = transfer?.sendProcess?.currentTransferStage;
		return value;
	}, [transfer]);

	const isNotOwn = useMemo(() => {
		const value = order?.isOwn;
		return isBoolean(value) ? !value : false;
	}, [order?.isOwn]);

	const hasExecutor = useMemo(() => {
		if (!order?.id) return true;
		const value = order?.executorToOrder?.length;
		return !!value;
	}, [order?.executorToOrder?.length, order?.id]);

	const isCurrentTransferStage = useMemo(() => {
		if (hasExecutor) return true;
		return !currentTransferStage;
	}, [currentTransferStage, hasExecutor]);

	const disabledOrder = useMemo(() => {
		if (!orderId) return true;
		return false;
	}, [orderId]);

	const returnFromTransfer = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;

		Order.returnFromTransfer(orderId);
	}, [orderId, isNotOwn]);

	const sendToTransfer = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		Order.sendToTransfer(orderId);
	}, [isNotOwn, orderId]);

	const sendToTransferLocal = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		Order.sendToTransfer(orderId, { type: OrderTransferType.LOCAL });
	}, [isNotOwn, orderId]);

	const sendToTransferGlobal = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		Order.sendToTransfer(orderId, { type: OrderTransferType.GLOBAL });
	}, [isNotOwn, orderId]);

	const sendToTransferOutside = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		Order.sendToTransfer(orderId, { type: OrderTransferType.OUTSIDE });
	}, [isNotOwn, orderId]);

	// const isNotAccess = useMemo(
	// 	() =>
	// 		!hasAccess(
	// 			generateAccessName(ACCESS_SECTION, ACCESS_NAME),
	// 			personalRole,
	// 		),
	// 	[ACCESS_SECTION, ACCESS_NAME, personalRole],
	// );

	// if (isNotAccess) return null;

	return (
		<StyledRow gap="5px">
			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					isCurrentTransferStage ||
					isNotOwn
				}
				title={"Вернуть заказ из перекидки"}
				tabIndex={-1}
				onClick={returnFromTransfer}
			>
				<Icon id="return-back" size={18} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					!!currentTransferStage ||
					isNotOwn ||
					isNotEnabled
				}
				title={"Отправить в перекидку"}
				tabIndex={-1}
				onClick={sendToTransfer}
			>
				<Icon id="sendTransferSend" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.LOCAL ||
					currentTransferStage === OrderTransferType.GLOBAL ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={"Отправить в 'Локальную' перекидку"}
				tabIndex={-1}
				onClick={sendToTransferLocal}
			>
				<Icon id="sendTransferLocal" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.GLOBAL ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={"Отправить в 'Глобальную' перекидку"}
				tabIndex={-1}
				onClick={sendToTransferGlobal}
			>
				<Icon id="sendTransferGlobal" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={"Отправить во 'Внешнию' перекидку"}
				tabIndex={-1}
				onClick={sendToTransferOutside}
			>
				<Icon id="sendTransferOutside" size={22} />
			</Button>
		</StyledRow>
	);
};

export const OrderTransferButtons = memo(OrderTransferButtonsBase);

declare namespace OrderTransferButtonsBase {
	interface Props {
		orderId?: number;
		disabled?: boolean;
		order?: Order.Model;
	}
}

export default OrderTransferButtonsBase;
