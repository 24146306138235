import makeTable from "./makeTable";

const settingsOrderExchangeMain = makeTable({
	allIds: [
		"filFrom",
		"keyFrom",

		"filTo",
		"keyTo",
		"orderReceiving",
		"orderSending",
	],
	defaultIds: [
		"filFrom",
		"keyFrom",

		"filTo",
		"keyTo",
		"orderReceiving",
		"orderSending",
	],
	i18tPath: (id) => `settings.orderExchange.main.columns.${id}`,
	initialSortMethod: {
		column: "filTo",
		type: "asc",
	},
	initialWidths: {
		filFrom: 200,
		keyFrom: 80,

		filTo: 200,
		keyTo: 80,
		orderReceiving: 160,
		orderSending: 160,
	},
	initialResizable: {
		filFrom: true,
		keyFrom: true,

		filTo: true,
		keyTo: true,
		orderReceiving: true,
		orderSending: true,
	},
	initialSortable: {
		filFrom: true,
		keyFrom: true,

		filTo: true,
		keyTo: true,
		orderReceiving: true,
		orderSending: true,
	},
});

export default settingsOrderExchangeMain;
