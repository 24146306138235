export const baseHost = process?.env?.REACT_APP_BASE_HOST;
export const baseSocket = process?.env?.REACT_APP_BASE_SOCKET_HOST;

// export const baseSocket = "ws://localhost:9092/";
// export const baseSocket = "wss://c22d-185-67-252-124.ngrok-free.app";
// export const baseSocket = "wss://api.beta.demo.express-soft.org/";
// export const baseSocket = "wss://api.express-t.express-soft.org/";
// export const baseSocket = "wss://api.b2b-urban.express-soft.org/";
// export const baseSocket = "wss://api.taxi571.express-soft.org/";
// export const baseSocket = "wss://api.taxi.999.md";
