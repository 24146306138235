import React, { useMemo } from "react";
import { theme } from "uikit";

import Order, {
	OrderReminderStatus,
} from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { Language } from "../../../../../../../services";

const backgroundColorByStatus = {
	offer: "#51C3F7",
	executing: "rgb(76, 175, 80)",
	inactive: "#ebebeb",
	send: "#FFA500",
	dispatcherBGC: theme.colors.disabled_text,
	dispatcherText: theme.colors.white,
};

const ExecutorCallSignCellContent: React.FC<
	ExecutorCallSignCellContent.Props
> = ({ item, language }) => {
	const lastReceivedOrder = useMemo(() => {
		const value = item?.additionalFields?.transfer?.lastReceivedOrder;
		return value;
	}, [item]);

	const fromTransferExecutors = useMemo(
		() => lastReceivedOrder?.executorToOrder,
		[lastReceivedOrder],
	);

	const executor = useMemo(() => {
		if (item?.executorToOrder && item?.executorToOrder?.length) {
			return item?.executorToOrder?.[0]?.executor;
		}
		if (fromTransferExecutors && fromTransferExecutors?.length) {
			return fromTransferExecutors?.at(0);
		}

		return item.offer?.executor || lastReceivedOrder?.offer?.executor;
	}, [
		item?.executorToOrder,
		item.offer?.executor,
		fromTransferExecutors,
		lastReceivedOrder?.offer?.executor,
	]);

	const isOwn = useMemo(() => item?.isOwn, [item]);

	const taxiService = useMemo(() => item.taxiService, [item]);

	const taxiServiceId = useMemo(() => taxiService?.id, [taxiService?.id]);

	const settlement = useMemo(
		() => taxiService?.settlement?.[language],
		[language, taxiService],
	);

	const company = useMemo(
		() => taxiService?.company?.name?.[language],
		[language, taxiService],
	);

	const executorTaxiServiceId = useMemo(
		() => executor?.taxiService?.id,
		[executor?.taxiService?.id],
	);

	const executorSettlement = useMemo(
		() => executor?.taxiService?.settlement?.[language],
		[executor?.taxiService?.settlement, language],
	);

	const executorCompany = useMemo(
		() => executor?.taxiService?.company?.name?.[language],
		[executor?.taxiService?.company?.name, language],
	);

	const content = useMemo(() => {
		if (!isOwn) {
			const list = [
				taxiServiceId === executorTaxiServiceId,
				settlement === executorSettlement,
				company === executorCompany,
			];

			if (list.every((item) => item === true)) {
				return executor?.callSign;
			}

			return "";
		}
		return executor?.callSign;
	}, [
		company,
		executor?.callSign,
		executorCompany,
		executorSettlement,
		executorTaxiServiceId,
		isOwn,
		settlement,
		taxiServiceId,
	]);

	const isDispatcher = useMemo(
		() =>
			item?.executorToOrder?.find(
				(item) =>
					item?.executor.id === executor?.id &&
					item?.additionalFields?.acceptedByDispatcher?.active,
			) ||
			fromTransferExecutors?.find(
				(item) =>
					item?.executor?.id === executor?.id &&
					item?.additionalFields?.acceptedByDispatcher?.active,
			),
		[executor?.id, fromTransferExecutors, item?.executorToOrder],
	);

	const backgroundColor = useMemo(() => {
		if (lastReceivedOrder) {
			if (lastReceivedOrder?.executorReminderStatus) {
				const { status } = lastReceivedOrder.executorReminderStatus;
				if (status && OrderReminderStatus.SEND === status) {
					return backgroundColorByStatus.send;
				}
				if (lastReceivedOrder?.executingStages?.accepted) {
					return backgroundColorByStatus.executing;
				}
			}

			if (
				(isDispatcher &&
					lastReceivedOrder?.executingStages?.assigned &&
					!lastReceivedOrder?.executingStages?.accepted) ||
				(isDispatcher &&
					!lastReceivedOrder?.offer?.executor?.online &&
					!lastReceivedOrder?.executingStages?.accepted)
			) {
				return backgroundColorByStatus.dispatcherBGC;
			}

			if (lastReceivedOrder?.executingStages?.assigned) {
				return backgroundColorByStatus.inactive;
			}

			if (lastReceivedOrder?.offer?.executor?.online) {
				return lastReceivedOrder.offer.executor.online
					? backgroundColorByStatus.offer
					: backgroundColorByStatus.inactive;
			}
		}

		if (item?.executorReminderStatus) {
			const { status } = item.executorReminderStatus;
			if (status && OrderReminderStatus.SEND === status) {
				return backgroundColorByStatus.send;
			}
		}

		if (item.executingStages?.accepted) {
			return backgroundColorByStatus.executing;
		}

		if (
			(isDispatcher &&
				item.executingStages?.assigned &&
				!item.executingStages?.accepted) ||
			(isDispatcher &&
				!item?.offer?.executor?.online &&
				!item.executingStages?.accepted)
		) {
			return backgroundColorByStatus.dispatcherBGC;
		}

		if (item.executingStages?.assigned) {
			return backgroundColorByStatus.inactive;
		}

		if (item.offer?.executor?.online) {
			return item.offer?.executor?.online
				? backgroundColorByStatus.offer
				: backgroundColorByStatus.inactive;
		}

		return undefined;
	}, [
		lastReceivedOrder,
		item.executorReminderStatus,
		item.executingStages?.accepted,
		item.executingStages?.assigned,
		item.offer?.executor?.online,
		isDispatcher,
	]);

	const textColor = useMemo(
		() =>
			(isDispatcher && item.executingStages?.assigned) ||
			(isDispatcher && !item?.offer?.executor?.online)
				? theme.colors.white
				: undefined,
		[
			isDispatcher,
			item.executingStages?.assigned,
			item?.offer?.executor?.online,
		],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorCallSignCellContent {
	interface Props {
		item: Order.Model;
		language: Language;
	}
}

export default ExecutorCallSignCellContent;
