import React, { Dispatch, memo, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SortType } from "rsuite-table";
import { extend, isString, isUndefined, pick, pickBy } from "lodash";
import Color from "color";
import { useRefSize } from "uikit";

import CompactTable from "../../../../CompactTable";
import TableSettings from "../../../../TableSettings";
import Language from "../../../../../services/Language";
import Order from "../../../../../services/Order";
import makeLookupTable from "../../../../../utils/makeLookupTable";
import { useTypedSelector } from "../../../../../redux/store";
import useKeyBind from "../../../../../hooks/useKeyBind";

import Root from "./components/Root";
import TableRoot from "./components/TableRoot";
import columnBase, {
	ColumnId,
	ColumnId as LocalColumnId,
	defaultColumnIds,
} from "./columns";
import useOrderSortData from "./hooks/useOrderSortData";

const TABLE_HEADER_HEIGHT = 26;
const TABLE_ROW_HEIGHT = 32;

const ModelTableBase = memo(
	({
		selected,
		sort,
		columns,

		loading,
		data,
		language,

		onChangeSelected,
		onChangeSort,
		onChangeColumns,

		onEdit,
		onLoadMore,
	}: ModelTable.Props) => {
		const { t } = useTranslation();

		const { sortOrders } = useOrderSortData();

		const items = useMemo(() => {
			if (!data.length) return [];
			return sortOrders(data, {
				column: sort?.column || ColumnId.ExecutorArrivalTime,
				type: sort?.type || "asc",
			});
		}, [data, sortOrders, sort?.type, sort?.column]);

		const tableSettingColumns = useMemo(
			() =>
				defaultColumnIds.map((defaultColumnId) => ({
					id: defaultColumnId,
					label: t([
						columnBase[defaultColumnId]?.pathTranslation,
						columnBase[defaultColumnId]?.label,
						"",
					]),
				})),
			[t],
		);

		const canLoadMoreRef = useRef(true);
		const tableRef = useRef<CompactTable.Ref | null>(null);

		const { ref: tableRootRef, size: tableRootSize } = useRefSize();

		const colors = useTypedSelector((state) => state.settings.colors);

		const columnLookup = useMemo(
			() => makeLookupTable(columns, (column) => column.id, true),
			[columns],
		);

		const tableRootColors = useMemo(() => {
			const defaultOrderColors = pickBy(colors, (_, colorName) =>
				colorName.startsWith("color"),
			);

			const paymentTypeOrderColors = pick(colors, [
				"app",
				"phone",
				"website",
				"executor",
				"dispatcher",
				"orderFromTransfer",
				"orderAcceptedFromTransfer",
				"orderToTransfer",
				"orderAcceptedToTransfer",
			]);

			const orderColors = {
				...defaultOrderColors,
				...paymentTypeOrderColors,
			};

			const selectedOrderColors = Object.fromEntries(
				Object.entries(orderColors).map(([colorName, colorValue]) => {
					const color = Color(colorValue as string);

					return [
						`selected-${colorName}`,
						color.white() === 100
							? colors.activeOrder
							: color.darken(0.1).hex(),
					];
				}),
			);

			return {
				selected: colors.activeOrder,
				...orderColors,
				...selectedOrderColors,
			};
		}, [colors]);

		const tableRowClassName = useCallback(
			(item?: Order.Model) => {
				if (isUndefined(item)) return "";

				const orderColor = item.additionalFields.displaySettings?.color;

				const classNameNodes: string[] = [];

				if (selected === item.id) {
					classNameNodes.push("selected");
				}

				if (isString(orderColor) && orderColor !== "none") {
					classNameNodes.push(orderColor as string);
				} else if (
					!isUndefined(item.isOwn) &&
					item?.additionalFields?.transfer
				) {
					const {
						isOwn,
						additionalFields,
						executorToOrder,
						taxiService,
					} = item;
					const { transfer } = additionalFields;

					const executor =
						executorToOrder && executorToOrder?.length
							? executorToOrder?.[0]?.executor
							: undefined;

					if (
						isOwn &&
						executor?.taxiService?.id !== taxiService?.id
					) {
						if (
							transfer?.sendProcess?.currentTransferStage &&
							!executorToOrder?.length &&
							!transfer?.lastReceivedOrder?.executorToOrder
								?.length
						) {
							classNameNodes.push("orderToTransfer");
						}

						if (
							transfer?.lastReceivedOrder?.executorToOrder?.length
						) {
							classNameNodes.push("orderAcceptedFromTransfer");
						}
					}

					if (!isOwn) {
						if (!executorToOrder?.length) {
							classNameNodes.push("orderAcceptedToTransfer");
						}

						if (executorToOrder?.length) {
							classNameNodes.push("orderFromTransfer");
						}
					}
				} else {
					classNameNodes.push(item.source);
				}

				return classNameNodes.join("-");
			},
			[selected],
		);

		const onLoadMoreRef = useRef(onLoadMore);

		onLoadMoreRef.current = onLoadMore;

		const tableOnScroll = useMemo(() => {
			canLoadMoreRef.current = true;

			return (x: number, y: number) => {
				if (!canLoadMoreRef.current) return;

				const contextHeight = items.length * TABLE_ROW_HEIGHT;
				const top = Math.abs(y);
				const tableContainerHeight =
					tableRef.current?.root.getBoundingClientRect().height ?? 0;

				if (contextHeight - top - tableContainerHeight < 10) {
					onLoadMoreRef.current();

					canLoadMoreRef.current = false;
				}
			};
		}, [items.length]);

		const tableOnRowClick = useCallback(
			(rowData: Order.Model) => onChangeSelected(rowData.id),
			[onChangeSelected],
		);

		const tableOnRowDoubleClick = useCallback(
			(rowData: Order.Model) => onEdit(rowData.id),
			[onEdit],
		);

		const tableOnChangeSort = useCallback(
			(column: string, type: SortType = "asc") =>
				onChangeSort({ column: column as LocalColumnId, type }),
			[onChangeSort],
		);

		const tableSettingsValue = useMemo(
			() =>
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			[columns],
		);

		const tableSettingsOnChange = useCallback(
			(columnIds: string[]) => {
				const otherColumns = columns
					.filter((column) => !columnIds.includes(column.id))
					.map((column) => ({ ...column, visible: false }));

				const newColumns = columnIds.map((columnId) => ({
					id: columnId as LocalColumnId,
					width: columnLookup[columnId]?.value.width,
					visible: true,
				}));

				const a = [...newColumns, ...otherColumns];

				onChangeColumns(a);
			},
			[columnLookup, columns, onChangeColumns],
		);

		const renderedColumns = useMemo(
			() =>
				columns
					.filter((column) => column.visible)
					.map((column) => {
						const node = columnBase[column.id].render({
							width: column.width || 100,
							language,

							onResize: (width, columnId) => {
								const newColumns = columns.map((columns) =>
									columns.id === columnId
										? { ...columns, width: width ?? 0 }
										: columns,
								);

								onChangeColumns(newColumns);
							},
						});

						return node;
					}),
			[columns, language, onChangeColumns],
		);

		const moveSelectedBy = useCallback(
			(offset: number) => {
				const selectedIndex = items.findIndex(
					(order) => order.id === selected,
				);

				if (selectedIndex === -1) return;

				const nextSelectedIndex = Math.max(
					Math.min(selectedIndex + offset, items.length - 1),
					0,
				);

				onChangeSelected(items[nextSelectedIndex].id);
			},
			[items, onChangeSelected, selected],
		);

		useKeyBind(["ArrowUp"], (event) => {
			event.preventDefault();
			event.stopPropagation();

			moveSelectedBy(-1);
		});

		useKeyBind(["ArrowDown"], (event) => {
			event.preventDefault();
			event.stopPropagation();

			moveSelectedBy(1);
		});

		return (
			<Root sizes="1fr auto!" maxedWidth maxedHeight>
				<TableRoot ref={tableRootRef} colors={tableRootColors}>
					<CompactTable
						ref={tableRef}
						fillHeight
						virtualized
						height={tableRootSize.height}
						disableDoubleClickDebounce
						shouldUpdateScroll={false}
						headerHeight={TABLE_HEADER_HEIGHT}
						rowHeight={TABLE_ROW_HEIGHT}
						sortColumn={sort?.column}
						sortType={sort?.type}
						loading={loading}
						data={items}
						rowClassName={tableRowClassName}
						onScroll={tableOnScroll}
						onRowClick={tableOnRowClick}
						onRowDoubleClick={tableOnRowDoubleClick}
						onSortColumn={tableOnChangeSort}
					>
						{renderedColumns}
					</CompactTable>
				</TableRoot>

				<TableSettings
					value={tableSettingsValue}
					defaultValue={defaultColumnIds}
					columns={tableSettingColumns}
					onChange={tableSettingsOnChange}
				/>
			</Root>
		);
	},
);

const ModelTable = extend(ModelTableBase, {
	ColumnId: LocalColumnId,
});

declare namespace ModelTable {
	type ColumnId = LocalColumnId;

	interface Sort {
		column?: LocalColumnId;
		type?: SortType;
	}

	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}

	interface Props {
		selected?: number;
		sort?: Sort;

		columns: Column[];
		loading?: boolean;
		data: Order.Model[];
		language: Language;

		onChangeSelected: Dispatch<number>;
		onChangeSort: Dispatch<Sort>;
		onChangeColumns: Dispatch<Column[]>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
